<template>
  <v-container fluid pt-8 px-3>
    <v-layout row wrap dense justify-space-between>
      <v-flex v-for="terminal in terminalsSelectItems"
              :key="terminal.id" v-bind="{ [`xs4`]: true }">
        <v-hover>
          <v-card slot-scope="{ hover }" 
                    :class="`elevation-${hover ? 12 : 2}`" 
                    class="my-4 mx-auto" 
                    shaped max-width="400">
              <v-img :src="terminal.img"
                        class="white--text align-end"  
                        height="220px"
                        v-on:click="goToTerminal(terminal.value)"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">   
                    <v-card-title>{{ terminal.text }}</v-card-title>
              </v-img>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { UI } from '@/store/ui';
import { TERMINALS } from '@/store/terminals';
import { ROUTE_NAMES } from '@/routes';

export default Vue.extend({
 watch: {
    terminalsByKey: {
      handler() {
        if(localStorage.lastUsedTerminalId){
          this.goToTerminal(Number(localStorage.lastUsedTerminalId));
        }
      }
    }
  },
  computed: {
        isLoading() {
            return this.$store.getters[UI.NS(UI.GETTERS.IS_LOADING)];
        },
        activeTerminal: {
          get() {
            const activeTerminal =
              this.$store.state.terminals &&
              this.$store.state.terminals.activeTerminal &&
              this.$store.state.terminals.activeTerminal.id;
            return activeTerminal;
          },
          set(activeTerminal) {
            this.$store.dispatch(
              TERMINALS.NS(TERMINALS.ACTIONS.SET_ACTIVE_TERMINAL),
              activeTerminal
            );
            this.$router.push({
                name: ROUTE_NAMES.OPERATIONSELECT,
                params: { terminalId:  activeTerminal.id}
            });
          }
        },
        ...mapState({
            terminal: state => state.terminals.activeTerminal,
            terminalsByKey: state => state.terminals.terminalsList &&
              state.terminals.terminalsList.reduce((terminals, terminal) => {
                terminals[terminal.id] = { ...terminal };
                return terminals;
            }, {}),
            terminalsSelectItems: state => state.terminals.terminalsList &&
              state.terminals.terminalsList.map(terminal => ({
                value: terminal.id,
                text: terminal.name,
                img: `/images/${terminal.shortName}.png`,
                favourite: false
            })),
            isLoading: state => state.ui.isLoading
        })
    },
    methods: {
        goToTerminal(terminalId) {
          this.activeTerminal = this.terminalsByKey[terminalId];
        }
    }
});
</script>

<style lang="scss" scoped>
  .resultContainer {
    height: 350px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .item {
    min-height: 50px;
    min-width: 80px;
    max-width: 85%;
  }
</style>